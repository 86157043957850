<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="success"
        class="my-1 mr-1"
        @click="openCreateReasonModal()"
      >
        <feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />Create Reason
      </b-button>
    </div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refReasonTable'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          :fields="fields"
          :items="myProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          ref="refReasonTable"
          no-border-collapse
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          responsive
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex align-items-center" style="gap: 5px">
              <b-badge v-if="data.item.status == 'ACTIVE'" variant="success">
                Active
              </b-badge>
              <b-badge v-else variant="danger"> Inactive </b-badge>
              <tabler-icon
                icon="SettingsIcon"
                size="15"
                :class="
                  data.item.status == 'ACTIVE' ? 'text-warning' : 'text-success'
                "
                class="mr-1 cursor-pointer"
                v-b-tooltip.hover.top="'Change Status Reason'"
                @click="updateReason(data.item)"
              />
            </div>
          </template>
          <template #cell(documents)="data">
            <div
              class="d-flex justify-content-start align-items-start"
              style="flex-wrap: wrap; gap: 5px"
            >
              <template v-if="!data.item.showAll">
                <b-badge
                  variant="light-info"
                  v-for="(doc, index) in JSON.parse(data.item.documents).slice(
                    0,
                    3
                  )"
                  :key="index"
                >
                  {{ doc.name.toUpperCase() }}
                </b-badge>
              </template>
              <template v-else>
                <b-badge
                  v-for="(doc, index) in JSON.parse(data.item.documents)"
                  :key="index"
                  variant="light-info"
                  >{{ doc.name.toUpperCase() }}</b-badge
                >
              </template>
              <span
                @click="data.item.showAll = true"
                v-if="
                  JSON.parse(data.item.documents).length > 3 &&
                  !data.item.showAll
                "
                class="text-primary cursor-pointer"
              >
                see more...
              </span>
              <span
                @click="data.item.showAll = false"
                v-if="data.item.showAll"
                class="text-primary cursor-pointer"
              >
                see less...
              </span>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span
              >{{ data.item.created_by }} <br />
              {{ data.item.created_at | myGlobalDay }}</span
            >
          </template>
          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              size="15"
              class="text-primary mr-1 cursor-pointer"
              v-b-tooltip.hover.top="'Edit Reason'"
              @click="openEditReasonModal(data.item)"
            />
            <feather-icon
              icon="TrashIcon"
              size="15"
              class="text-danger mr-1 cursor-pointer"
              v-b-tooltip.hover.top="'Delete Reason'"
              @click="deleteReason(data.item.id)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <create-reason
      v-if="modalCreateState"
      :mood="mood"
      :reason-data="reasonSend"
      @close="closeModalReason"
      @reload="$refs['refReasonTable'].refresh()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import SettingService from "@/views/management/views/settings/services/index"
import dataFilters from "@/views/management/views/settings/components/dataFilters.data"
import CreateReason from "@/views/management/views/settings/views/reason-code/modal/CreateReason.vue"
export default {
  components: {
    CreateReason,
  },
  data() {
    return {
      modalCreateState: false,
      mood: 0,
      fields: [
        {
          key: "title",
          label: "title",
        },
        {
          key: "description",
          label: "Reason Description",
          thStyle: {
            maxWidth: "35%",
          },
        },
        {
          key: "status",
          label: "status",
        },
        {
          key: "documents",
          label: "Document",
        },
        {
          key: "created_at",
          label: "creation by",
        },
        {
          key: "actions",
          label: "actions",
          thStyle: {
            width: "150px",
          },
        },
      ],
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      totalRows: 0,
      toPage: 0,
      filter: dataFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      reasonSend: {},
    }
  },
  methods: {
    modalCreateReason() {
      this.modalCreateState = true
    },
    closeModalReason() {
      this.modalCreateState = false
    },
    async myProvider(ctx) {
      try {
        let params = {
          title: this.filterPrincipal.model,
          orderby: 1,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          status: this.filter[2].model,
          from: this.filter[0].model,
          to: this.filter[1].model,
        }
        const result = await SettingService.searchReasonCode(params)
        const data = result.data.map((item) => ({ ...item, showAll: false }))
        this.startPage = result.from
        this.toPage = result.to
        this.totalRows = result.total
        this.paginate.currentPage = result.current_page
        this.paginate.perPage = result.per_page
        return data
      } catch (error) {
        this.showErrorSwal(error)
      }
    },

    async deleteReason(id) {
      try {
        const response = await this.showConfirmSwal(
          "Are you sure you want to delete reason"
        )
        if (response.isConfirmed) {
          this.addPreloader()
          const data = await SettingService.deleteReasonCode({ id: id })
          if (data.status === 200) {
            this.removePreloader()
            this.$refs.refReasonTable.refresh()
            this.showSuccessSwal("Reason code has been deleted successfully")
          }
        }
      } catch (error) {
        this.removePreloader()
        this.showWarningSwal(
          "Denied",
          "It is possible that this reason code is being used, if so, it cannot be deleted, if not, please contact the support team."
        )
      }
    },

    openEditReasonModal(item) {
      this.modalCreateState = true
      this.reasonSend = item
      this.mood = 2
    },
    openCreateReasonModal() {
      this.modalCreateState = true
      this.mood = 1
      this.reasonSend = {}
    },
    async updateReason(item) {
      try {
        const response = await this.showConfirmSwal(
          "Are you sure you want to update status reason"
        )
        let statusReason = item.status == "ACTIVE" ? 2 : 1
        if (response.isConfirmed) {
          this.addPreloader()
          const data = await SettingService.updateReasonCode({
            id: item.id,
            status: statusReason,
          })
          if (data.status === 200) {
            this.removePreloader()
            this.$refs.refReasonTable.refresh()
            this.showSuccessSwal("Reason status has been updated successfully")
          }
        }
      } catch (error) {
        this.removePreloader()
        this.showErrorSwal(error)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },
}
</script>

import { amgApi } from "@/service/axios";

class SettingsService {
  async searchReasonCode(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/get-reason-code-charge-back",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error searchReasonCode");
    }
  }

  async saveReasonCode(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/save-reason-code-charge-back",
        params
      );
      return data;
    } catch (error) {
      console.log("Error saveReasonCode");
    }
  }

  async deleteReasonCode(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/delete-reason-code-charge-back",
        params
      );
      return data;
    } catch (error) {
      console.log("Error deleteReasonCode");
    }
  }

  async updateReasonCode(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/update-reason-code-charge-back",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveDescription(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/save-documents-reason",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDescription() {
    try {
      const data = await amgApi.post(
        "management/charge-back/get-documents-reason"
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SettingsService();

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","modal":"","size":"md","header-class":"p-0","no-close-on-backdrop":"","modal-class":"modal-primary modal-reason-settings","title":_vm.title,"title-class":"h3 text-white p-1"},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button-cancel',{on:{"click":function($event){return cancel()}}}),_c('button-save',{on:{"click":function($event){return _vm.saveReasonCode()}}})]}}]),model:{value:(_vm.modalCenter),callback:function ($$v) {_vm.modalCenter=$$v},expression:"modalCenter"}},[_c('validation-observer',{ref:"form"},[_c('validation-provider',{attrs:{"name":"title","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-25"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("TITLE")])])],1),_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.titleReason),callback:function ($$v) {_vm.titleReason=$$v},expression:"titleReason"}})],1)],1)]}}])}),_c('validation-provider',{attrs:{"name":"desc","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-25"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("DESCRIPTION")])])],1),_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.descriptionReason),callback:function ($$v) {_vm.descriptionReason=$$v},expression:"descriptionReason"}})],1)],1)]}}])}),_c('validation-provider',{attrs:{"name":"document","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-2"},[_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-25"},[_c('b-input-group-text',{staticClass:"w-100"},[_c('span',[_vm._v("DOCUMENTS")])])],1),_c('v-select',{staticClass:"form-control bg-transparent hardcode-select mul-hard-select",class:errors[0] ? 'border-danger' : '',staticStyle:{"height":"auto","max-height":"80px"},attrs:{"options":_vm.optionsDescription,"multiple":true,"label":"name","placeholder":"Select Documents"},model:{value:(_vm.selectedDocuments),callback:function ($$v) {_vm.selectedDocuments=$$v},expression:"selectedDocuments"}}),(!_vm.showPlusDocuments)?_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Add document'),expression:"'Add document'",modifiers:{"hover":true,"top":true}}],staticStyle:{"cursor":"pointer"},attrs:{"is-text":""},on:{"click":_vm.showDocDes}},[_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"PlusCircleIcon"}})],1):_vm._e()],1)],1)],1)]}}])})],1),(_vm.showPlusDocuments)?_c('validation-observer',{ref:"formDocument"},[_c('validation-provider',{attrs:{"name":"description","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hr'),_c('div',{staticClass:"my-1 text-info"},[_vm._v("CREATE NEW DOCUMENT")]),_c('b-form-group',{attrs:{"label-for":"input-7"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-25"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("NEW DOC.")])])],1),_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"outline-info"},on:{"click":_vm.saveDescription}},[_vm._v(" Save Description ")])],1)]}}],null,false,1110606877)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    v-model="modalCenter"
    centered
    modal
    size="md"
    header-class="p-0"
    no-close-on-backdrop
    modal-class="modal-primary modal-reason-settings"
    :title="title"
    title-class="h3 text-white p-1"
    @hidden="close"
  >
    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors }"
        name="title"
        :rules="{ required: true }"
      >
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-25">
              <b-input-group-text class="w-100 badge-name-group">
                <span>TITLE</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              v-model="titleReason"
              :state="errors[0] ? false : null"
            />
          </b-input-group>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="desc"
        :rules="{ required: true }"
      >
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-25">
              <b-input-group-text class="w-100 badge-name-group">
                <span>DESCRIPTION</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              v-model="descriptionReason"
              :state="errors[0] ? false : null"
            />
          </b-input-group>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="document"
        :rules="{ required: true }"
      >
        <div class="mt-2">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-25">
                <b-input-group-text class="w-100">
                  <span>DOCUMENTS</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <v-select
                v-model="selectedDocuments"
                :options="optionsDescription"
                :multiple="true"
                label="name"
                placeholder="Select Documents"
                class="form-control bg-transparent hardcode-select mul-hard-select"
                style="height: auto; max-height: 80px"
                :class="errors[0] ? 'border-danger' : ''"
              ></v-select>
              <b-input-group-append
                is-text
                style="cursor: pointer"
                @click="showDocDes"
                v-b-tooltip.hover.top="'Add document'"
                v-if="!showPlusDocuments"
              >
                <feather-icon icon="PlusCircleIcon" class="text-success" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </validation-provider>
    </validation-observer>

    <validation-observer ref="formDocument" v-if="showPlusDocuments">
      <validation-provider
        v-slot="{ errors }"
        name="description"
        :rules="{ required: true }"
      >
        <hr />
        <div class="my-1 text-info">CREATE NEW DOCUMENT</div>
        <b-form-group label-for="input-7">
          <b-input-group>
            <b-input-group-prepend class="w-25">
              <b-input-group-text class="w-100 badge-name-group">
                <span>NEW DOC.</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              v-model="description"
              :state="errors[0] ? false : null"
            />
          </b-input-group>
        </b-form-group>
        <div class="text-right">
          <b-button variant="outline-info" @click="saveDescription">
            Save Description
          </b-button>
        </div>
      </validation-provider>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="saveReasonCode()" />
    </template>
  </b-modal>
</template>
<script>
import SettingService from "@/views/management/views/settings/services/index"
import buttonSave from "@/views/commons/utilities/ButtonSave.vue"
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue"
import { mapGetters } from "vuex"
import vSelect from "vue-select"

export default {
  async created() {
    this.getDescription()
    this.title = this.mood === 1 ? "Create Reason Code" : "Edit Reason Code"
    if (this.mood !== 1) {
      this.titleReason = this.reasonData.title
      this.reasonId = this.reasonData.id
      this.descriptionReason = this.reasonData.description
      this.selectedDocuments = JSON.parse(this.reasonData.documents)
    }
  },
  components: { vSelect, buttonSave, buttonCancel },
  props: {
    mood: {
      type: Number,
      default: 1,
    },
    reasonData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      modalCenter: true,
      titleReason: "",
      reasonId: null,
      descriptionReason: "",
      showPlusDocuments: false,
      description: null,
      optionsDescription: [],
      selectedDocuments: null,
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async saveReasonCode() {
      try {
        this.showPlusDocuments = false
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            this.addPreloader()
            const data = await SettingService.saveReasonCode({
              title: this.titleReason,
              reason_id: this.reasonId,
              description: this.descriptionReason,
              created_by: this.currentUser.user_id,
              documents: JSON.stringify(this.selectedDocuments),
              number_document: this.selectedDocuments.length,
            })
            if (data.status === 200) {
              this.removePreloader()
              this.showSuccessSwal()
              this.close()
              this.$emit("reload")
            }
          }
        }
      } catch (error) {
        this.removePreloader()
        this.showErrorSwal(error)
      }
    },
    async saveDescription() {
      try {
        const result = await this.$refs.formDocument.validate()
        if (result) {
          this.addPreloader()
          const data = await SettingService.saveDescription({
            description: this.description,
          })
          if (data.status === 200) {
            this.showPlusDocuments = false
            this.getDescription()
            this.description = null
          }
        }
      } catch (error) {
        throw error
      } finally {
        this.removePreloader()
      }
    },

    async getDescription() {
      try {
        this.addPreloader()
        const data = await SettingService.getDescription()
        this.optionsDescription = data.map((item) => {
          return {
            name: item.description,
            id: item.id,
          }
        })
      } catch (error) {
        throw error
      } finally {
        this.removePreloader()
      }
    },

    showDocDes() {
      this.showPlusDocuments = true
    },

    hideDocDes() {
      this.showPlusDocuments = false
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
}
</script>
<style lang="scss">
.input-group-reason .v-select {
  width: 70% !important;
}
.input-group-reason .input-group-prepend {
  width: 20% !important;
}
.input-group-reason .input-group-append {
  width: 10% !important;
}
.modal-reason-settings .modal-sm {
  max-width: 28vw !important;
}
@media screen and (max-width: 767px) {
  .modal-reason-settings .modal-sm {
    max-width: 100vw !important;
  }
}
.hardcode-select .vs__dropdown-toggle {
  border: none;
  background: transparent !important;
  margin-top: -5px;
}
.mul-hard-select .vs__selected-options {
  height: auto;
  max-height: 80px;
  padding: 0 !important;
  overflow-y: auto;
}
</style>
